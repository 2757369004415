import { useDropdownButton } from "@octopusdeploy/design-system-components";
import type { QueryResult } from "@octopusdeploy/octopus-react-client";
import { useExperimentalQuery, useOctopusRepository } from "@octopusdeploy/octopus-react-client";
import { Permission } from "@octopusdeploy/octopus-server-client";
import type { ProjectResource, ProjectSummaryResource, ProjectGroupResource, ResourcesById } from "@octopusdeploy/octopus-server-client";
import { useOctopusSessionPermissions } from "@octopusdeploy/session";
import debounce from "lodash.debounce";
import React, { useEffect, useRef, useState } from "react";
import type { ProjectsState } from "./ControlledProjectSwitcher";
import { ControlledProjectSwitcher } from "./ControlledProjectSwitcher";
import type { ProjectSummaries, ProjectSummary } from "./ProjectSummary";
export interface ProjectSwitcherProps {
    projectsHref: string;
    getProjectHref: (project: ProjectSummary) => string;
    /**
     * When this array changes, any projects that have not already been loaded will be fetched from the server.
     */
    recentProjectIds: string[];
    onAddNewProjectRequested: () => void;
}
export function ProjectSwitcher({ projectsHref, getProjectHref, recentProjectIds, onAddNewProjectRequested }: ProjectSwitcherProps) {
    const [currentError, setCurrentError] = React.useState<Error>();
    const dropdownMenuState = useDropdownButton({ trapFocus: true, dropdownAriaRole: "dialog" });
    const [filter, setFilter] = useState("");
    const { refetchProjectGroupMap, projectGroupMap } = useProjectGroupMap();
    const { projects, allProjects, isLoadingProjects, projectsError, refetchProjects, totalProjects } = useFilteredProjectsWithPagingFallback(filter, projectGroupMap);
    const { projects: recentProjects, refetch: refetchRecentProjects } = useProjectsById(allProjects, recentProjectIds);
    const onFetchProjectsRequested = React.useCallback(() => {
        refetchProjects();
        refetchRecentProjects();
        refetchProjectGroupMap();
    }, [refetchProjects, refetchRecentProjects, refetchProjectGroupMap]);
    const repository = useOctopusRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const hasProjectViewPermission = currentPermissions.scopeToSpaceAndSystem(repository.spaceId ?? null).isAuthorized({
        permission: Permission.ProjectView,
    });
    const hasProjectCreatePermission = currentPermissions.scopeToSpaceAndSystem(repository.spaceId ?? null).isAuthorized({
        permission: Permission.ProjectCreate,
        projectGroupId: "*",
    });
    React.useEffect(() => {
        setCurrentError(dropdownMenuState.isOpen ? projectsError : undefined);
    }, [projectsError, dropdownMenuState.isOpen]);
    const clearCurrentError = () => setCurrentError(undefined);
    const isFilteringProjects = filter !== "";
    const projectsState: ProjectsState = getProjectsState(projects, recentProjects, totalProjects, isFilteringProjects, onAddNewProjectRequested, hasProjectCreatePermission);
    return (<>
            {hasProjectViewPermission && (<ControlledProjectSwitcher dropdownButtonState={dropdownMenuState} projectsState={projectsState} onFilterChange={setFilter} isLoading={isLoadingProjects} projectsHref={projectsHref} projectGroupMap={projectGroupMap} getProjectHref={getProjectHref} onFetchProjectsRequested={onFetchProjectsRequested} error={currentError} onCloseError={clearCurrentError}/>)}
        </>);
}
ProjectSwitcher.Controlled = ControlledProjectSwitcher;
function getProjectsState(projects: ProjectSummary[] | undefined, recentProjects: ProjectSummary[], totalProjects: number, isFilteringProjects: boolean, onAddNewProjectRequested: () => void, hasProjectCreatePermission: boolean): ProjectsState {
    if (!projects) {
        return {
            type: "initially-loading",
            recentProjects: recentProjects,
        };
    }
    if (isFilteringProjects) {
        return {
            type: "filtered",
            filteredProjects: projects,
            totalProjects,
        };
    }
    if (projects.length === 0) {
        return {
            type: "empty",
            onAddNewProjectRequested,
            hasProjectCreatePermission,
        };
    }
    return {
        type: "recently-viewed-and-all",
        allProjectsPageOne: projects,
        recentProjects,
        totalProjects,
    };
}
function filterProjects(filter: string, projects: ProjectSummary[] | undefined, projectGroupMap: ResourcesById<ProjectGroupResource>) {
    if (!projects || projects.length === 0 || !filter) {
        return projects;
    }
    const matchesFilter = (n: string) => n.toLowerCase().includes(filter.toLowerCase());
    return projects.filter((project) => {
        const { Name: projectName, GroupId: projectGroupId } = project;
        const projectGroupName = projectGroupId ? projectGroupMap[projectGroupId]?.Name : undefined;
        return matchesFilter(projectName) || (projectGroupName && matchesFilter(projectGroupName));
    });
}
function useFilteredProjectsWithPagingFallback(projectsFilter: string, projectGroupMap: ResourcesById<ProjectGroupResource>) {
    const allProjectsQuery = useAllProjectsQuery();
    const shouldQueryServerByPage = allProjectsQuery.data === undefined || allProjectsQuery.data.projects.length === 0;
    const pagedProjectsQuery = useProjectPagedFilterQuery(shouldQueryServerByPage, projectsFilter);
    if (shouldQueryServerByPage) {
        return {
            refetchProjects: pagedProjectsQuery.refetch,
            projects: pagedProjectsQuery.data?.projects,
            allProjects: allProjectsQuery.data?.projects,
            projectsError: pagedProjectsQuery.error ?? allProjectsQuery.error,
            isLoadingProjects: pagedProjectsQuery.isLoading || allProjectsQuery.isLoading,
            totalProjects: pagedProjectsQuery.data?.totalProjects ?? 0,
        };
    }
    return {
        refetchProjects: allProjectsQuery.refetch,
        projects: filterProjects(projectsFilter, allProjectsQuery.data?.projects, projectGroupMap),
        allProjects: allProjectsQuery.data?.projects,
        isLoadingProjects: allProjectsQuery.isLoading,
        projectsError: allProjectsQuery.error,
        totalProjects: allProjectsQuery.data?.totalProjects ?? 0,
    };
}
const PROJECT_FILTER_PAGE_SIZE = 30;
function useAllProjectsQuery(): QueryResult<ProjectSummaries> {
    const repository = useOctopusRepository();
    return useExperimentalQuery(async () => {
        const projects = await repository.Projects.summaries();
        return {
            projects: projects.map((p) => mapProjectSummaryResourceToModel(p)),
            totalProjects: projects.length,
        };
    }, [repository.Projects]);
}
function useProjectPagedFilterQuery(enabled: boolean, filter: string): QueryResult<ProjectSummaries | undefined> {
    const debouncedFilter = useDebouncedValue(filter);
    const repository = useOctopusRepository();
    return useExperimentalQuery(async () => {
        if (!enabled) {
            return undefined;
        }
        const data = await repository.Projects.list({ take: PROJECT_FILTER_PAGE_SIZE, ...(debouncedFilter ? { partialName: debouncedFilter } : {}) });
        return {
            projects: data.Items.map((p) => mapProjectResourceToModel(p)),
            totalProjects: data.TotalResults,
        };
    }, [enabled, debouncedFilter, repository.Projects]);
}
function useProjectGroupMap() {
    const repository = useOctopusRepository();
    const currentPermissions = useOctopusSessionPermissions();
    const hasProjectGroupPermissions = currentPermissions.scopeToSpaceAndSystem(repository.spaceId ?? null).isAuthorized({
        permission: Permission.ProjectGroupView,
        projectGroupId: "*",
    });
    const queryFn = () => (hasProjectGroupPermissions ? repository.ProjectGroups.allById() : Promise.resolve(undefined));
    const { refetch, data, isLoading, error } = useExperimentalQuery(queryFn, [hasProjectGroupPermissions, repository.ProjectGroups]);
    return {
        refetchProjectGroupMap: refetch,
        projectGroupMap: data ?? {},
        projectGroupMapIsLoading: isLoading,
        projectGroupMapError: error,
    };
}
function useProjectsById(allProjects: ProjectSummary[] | undefined, projectIds: string[]) {
    const repository = useOctopusRepository();
    const { refetch, data } = useExperimentalQuery(async () => {
        const allProjectsById = toLookup(allProjects ?? [], (p) => p.Id);
        const projectIdsToFetch = projectIds.filter((id) => allProjectsById[id] === undefined);
        const fetchedProjectResources = projectIdsToFetch.length > 0 ? await repository.Projects.all({ ids: projectIdsToFetch }) : [];
        const fetchedProjects = fetchedProjectResources.map(mapProjectResourceToModel);
        const fetchedProjectsById = toLookup(fetchedProjects, (p) => p.Id);
        return projectIds.map((id) => fetchedProjectsById[id] ?? allProjectsById[id]).filter(Boolean);
    }, [allProjects, projectIds, repository.Projects]);
    return {
        refetch,
        projects: data ?? [],
    };
}
function useDebouncedValue<T>(value: T) {
    const [debounceValue, setDebounceValue] = useState(value);
    const debouncedSetterRef = useRef(debounce((nextValue) => {
        if (debounceValue !== nextValue) {
            setDebounceValue(nextValue);
        }
    }, 250));
    useEffect(() => {
        const debouncedSet = debouncedSetterRef.current;
        debouncedSet(value);
        return () => {
            debouncedSet.cancel();
        };
    }, [value]);
    return debounceValue;
}
function mapBasePropertiesToModel(project: ProjectResource | ProjectSummaryResource): Omit<ProjectSummary, "Logo"> {
    return {
        Id: project.Id,
        Name: project.Name,
        Slug: project.Slug,
        GroupId: project.ProjectGroupId,
    };
}
function mapProjectResourceToModel(project: ProjectResource): ProjectSummary {
    return {
        ...mapBasePropertiesToModel(project),
        Logo: project.Links.Logo,
    };
}
function mapProjectSummaryResourceToModel(summary: ProjectSummaryResource): ProjectSummary {
    return {
        ...mapBasePropertiesToModel(summary),
        Logo: summary.Logo,
    };
}
function toLookup<T>(values: T[], keySelector: (value: T) => string): Record<string, T> {
    return values.reduce((valuesByKey: Record<string, T>, value) => {
        const key = keySelector(value);
        valuesByKey[key] = value;
        return valuesByKey;
    }, {});
}
